window.validateFileFormat = function(_target, callback=alert) {
  let target = $(_target)
  let file = _target.files[0]
  let file_format = target.data('file-format')
  let file_extensions = target.data('file-extensions')

  if (!file_extensions) {
    file_extensions = { 'video': 'mp4,webm', 'image': 'jpg,png,gif,svg' }[file_format]
  }
  if (file_extensions) {
    let file_extensions_regex = new RegExp(`\.(${file_extensions.replaceAll(',', '|')})$`, 'i')
    if (file.name.match(file_extensions_regex) == null) {
      target.val(null)
      callback(`This file format is not accepted. Please use ${file_extensions}.`)
      return
    }
  }

  let file_size_kb = file.size / 10240

  if (file_format == 'video') {
    let required_properties = ['max-size'].reduce( (c,f) => {c[f] = target.data(f); return c }, {} )
    required_properties['max-size'] ||= 1024
    if (file_size_kb > required_properties['max-size']) {
      target.val(null)
      callback(`File exceeds the size limit of ${required_properties['max-size']}KB.`)
    }
  }

  else if (file_format == 'image') {
    let required_aspect_ratio_str = target.data('aspect-ratio')
    let required_aspect_ratio = null
    if (required_aspect_ratio_str) {
      let required_aspect_ratio_parts = required_aspect_ratio_str.split(":")
      required_aspect_ratio = parseInt(required_aspect_ratio_parts[0]) / parseInt(required_aspect_ratio_parts[1])
    }
    let required_properties = ['min-height', 'min-width', 'max-height', 'max-width', 'max-size'].reduce( (c,f) => {c[f] = target.data(f); return c }, {} )
    required_properties['max-size'] ||= 2048

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      let image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        let height = this.height;
        let width = this.width;
        let aspect_ratio = width/height;

        let error = ""

        if (required_aspect_ratio && required_aspect_ratio != aspect_ratio) {
          error += ` The provided image does not have correct aspect ratio. Please prodive image with aspect ratio of ${required_aspect_ratio_str}.`
        }
        if ( (required_properties['min-height'] && height < required_properties['min-height']) || (required_properties['min-width'] && width < required_properties['min-width']) ) {
          error += ` The provided image should have a minimum size of ${required_properties['min-width']} X ${required_properties['min-height']}.`
        }
        if ( (required_properties['max-height'] && height > required_properties['max-height']) || (required_properties['max-width'] && width > required_properties['max-width']) ) {
          error += ` The provided image should have a maximum size of ${required_properties['max-width']} X ${required_properties['max-height']}.`
        }
        if ( required_properties['max-size'] && file_size_kb > required_properties['max-size'] ) {
          error += ` File exceeds the size limit of ${required_properties['max-size']}KBs.`
        }

        if ( error != "") {
          target.val(null)
          callback(error)
        }
        return true;
      }
    }
  }
}
$('.file-format-validation').on('change', (e) => {
  validateFileFormat(e.target)
})
